import Vue from 'vue';
import{
    Aside,
    Autocomplete,
    Button,
    Container,
    Col,
    Collapse,
    CollapseItem,
    Card,
    Checkbox,
    CheckboxGroup,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DatePicker,
    Dialog,
    Descriptions,
    DescriptionsItem,
    Form,
    FormItem,
    Footer,
    Header,
    Input,
    Icon,
    Image,
    InfiniteScroll,
    Link,
    Main,
    Menu,
    MenuItem,
    MenuItemGroup,
    Message,
    MessageBox,
    Option,
    Pagination,
    Row,
    Radio,
    RadioGroup,
    RadioButton,
    Submenu,
    Select,
    Switch,
    Table,
    TableColumn,
    Timeline,
    TimelineItem,
    Tag,
    Tabs,
    TabPane,
    Tree,
    Upload
} from "element-ui";

Vue.use(Aside);
Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Container);
Vue.use(Col);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Footer);
Vue.use(Header);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Image);
Vue.use(InfiniteScroll);
Vue.use(Link);
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Option);
Vue.use(Pagination);
Vue.use(Row);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Submenu);
Vue.use(Select);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Tag);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tree);
Vue.use(Upload);

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
