<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
  html,body {
    margin: 0;
    padding: 0;
  }
  #app {
    height: 100vh;
  }
</style>
